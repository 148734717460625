<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-skeleton-loader class="ma-3 pa-2" v-if="loading" v-bind="attrs" type="card-avatar"></v-skeleton-loader>
    <div v-else>
      <div class="text-center">
        <v-dialog v-model="dialog1" width="auto">
          <v-card>
            <v-card-title class="c-title">
              <v-row justify="space-between">
                Student Company Round Details
                <v-btn icon dark @click="dialog1 = false">
                  <v-icon color="black">mdi-close</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <template v-if="company != null">
                  <v-row>
                    <v-col md="6" sm="3">
                      <span class="lbl"> Company Name </span>
                    </v-col>
                    <v-col md="6" sm="3">
                      :{{ company.name }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="6" sm="3">
                      <span class="lbl"> Domain </span>
                    </v-col>
                    <v-col md="6" sm="3">
                      : <span v-for="(i, index) in company.industry" :key=index> {{ i }}<br></span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3" sm="3">
                      <span class="lbl"> Offering</span>
                    </v-col>
                    <v-col md="4" sm="4">
                      : {{ company.placementtype }}
                    </v-col>
                  </v-row>
                </template>
              </v-row>
              <v-row>
                <v-col cols="12" sm='3' v-for="item in studentround_list" :key="item">
                  <v-card>
                    <v-card-title class="c-title">
                      Round {{ item.round_number }} : {{ item.round_details }}
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6" sm="4">
                          <span class="lbl"> Result</span>
                        </v-col>
                        <v-col cols="6" md="6">
                          : <span v-if="item.round_isfinal == 'Pass'" style="color:green;font-weight:bold;">
                            {{ item.round_student_status }}
                          </span>
                          <span v-else style="color:red;font-weight:bold;">
                            {{ item.round_student_status }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-row justify="center">
                  <div style="margin-top:40px;margin-bottom:10px" v-if="this.finalselected == 'finalselected'">
                    <v-card>
                      <v-card-title class="c-title">
                        You Are Selected
                      </v-card-title>
                    </v-card>
                  </div>
                </v-row>
                <br>
              </v-row>
              <v-row justify="center">
                <v-btn class="logi" outlined text @click="dialog1 = false">Close</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <br>
      <v-card v-if="companyregistration_list.length > 0">
        <v-toolbar dense class="tc-title">
          <v-toolbar-title>Companies : Registration Open</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3" v-for="item in companyregistration_list" :key="item">
              <v-card style="margin: 3px; height: 100%">
                <v-card-title class="c-title">
                  {{ item.name }} :
                  <span>: {{ item.companytype }}</span>
                </v-card-title>
                <v-card-text>
                  <div style="padding: 4px"></div>
                  <v-row style="margin: 0px !important; padding: 0px !important">
                    <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                      <v-icon left class="black--text text--lighten-1">mdi-rename-box</v-icon>
                    </v-col>
                    <v-col cols="6" md="6" style="margin: 0px !important;padding: 0px !important;align: right;">
                      <strong class="black--text text--lighten-1">{{ item.placementtype }}</strong>
                      <span v-if="item.placementtype == 'Internship'">
                        <strong class="black--text text--lighten-1">- {{ item.internshiptype }}</strong>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row style="margin: 0px !important; padding: 0px !important">
                    <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                      <v-icon left title="Registration End Date Time"
                        class="black--text text--lighten-1">mdi-timer</v-icon>
                    </v-col>
                    <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                      <span v-if="item.regEnddate">
                        <strong class="black--text text--lighten-1">{{ item.regEnddate }} {{ item.regEndtime }}</strong>
                      </span>
                      <span v-else>
                        <strong class="black--text text--lighten-1"> - </strong>
                      </span>
                    </v-col>
                  </v-row>
                  <span v-if="item.applying == 'Criteria MisMatch' && !item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="red--text text--lighten-1"><v-icon left style="color: red">mdi
                            mdi-thumb-down-outline</v-icon></strong>
                      </v-col>
                      <v-col cols="6" sm="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="red--text text--lighten-1">{{ item.criteriamsg }}</strong>
                      </v-col>
                    </v-row>
                  </span>

                  <span v-else-if="item.applying == 'Applied' && !item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">
                          <v-icon left style="color: green">mdi mdi-thumb-up-outline</v-icon>
                        </strong>
                      </v-col>
                      <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">{{ item.applying }}</strong>
                      </v-col>
                    </v-row>
                  </span>
                  <span v-else-if="item.applying == 'Applied' && item.selected || item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">
                          <v-icon left style="color: green">mdi mdi-thumb-up-outline</v-icon>
                        </strong>
                      </v-col>
                      <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">Selected, Check Application Tracking Tab</strong>
                      </v-col>
                    </v-row>
                  </span>
                  <v-row>
                    <v-col cols="12" md="6">
                      <span v-if="item.registration == 'to_start'">
                        <span style="color:black;font-weight: bold;">Registration Yet to Open</span>
                      </span>
                      <span v-else-if="item.registration == 'Completed'">
                        <span style="color:blue;font-weight: bold;">
                          <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                          TPO Process Is Completed
                        </span>
                      </span>
                      <span v-else-if="item.registration == 'ended' && item.applying != 'Applied'">
                        <span style="color:black;font-weight: bold;">
                          <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                          Registration Closed
                        </span>
                      </span>
                      <span v-else-if="item.applying == 'true' && !item.selected">
                        <span>
                          <center><v-btn dense color="primary" @click="applyWithCV(item)"
                              v-model="details_id">Apply</v-btn>
                          </center>
                        </span>
                      </span>
                      <span
                        v-else-if="item.applying == 'Applied' || item.criteriamsg == 'You are added by Tpo in company' || item.applying == 'Selected'">
                        <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                        <span class="button" v-if="item.isallanswred == false">
                          <button type="submit" @click="newQuestionDialog(item)" class="button">Questions</button>
                        </span>
                      </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn color="primary" @click="finddetails(item)" v-model="details_id" dense>
                        More
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.selected && item.learneraccepted">
                    <v-img class="mx-auto" max-height="100" max-width="100" src="./tp.jpg" alt="Offer Accepted"></v-img>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <br>
      <v-card v-if="companyregistrationclosedwithapplied_list.length > 0">
        <v-toolbar dense class="tc3-title">
          <v-toolbar-title>Companies : Applications In Process</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3" v-for="item in companyregistrationclosedwithapplied_list" :key="item">
              <v-card style="margin: 5px; height: 100%">
                <v-card-title class="c3-title">
                  {{ item.name }} :
                  <span>: {{ item.companytype }}</span>
                </v-card-title>
                <v-card-text>
                  <div style="padding: 4px"></div>
                  <v-row style="margin: 0px !important; padding: 0px !important">
                    <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                      <v-icon left class="black--text text--lighten-1">mdi-rename-box</v-icon>
                    </v-col>
                    <v-col cols="6" md="6" style="margin: 0px !important;padding: 0px !important;align: right;">
                      <strong class="black--text text--lighten-1">{{ item.placementtype }}</strong>
                      <span v-if="item.placementtype == 'Internship'">
                        <strong class="black--text text--lighten-1">- {{ item.internshiptype }}</strong>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row style="margin: 0px !important; padding: 0px !important">
                    <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                      <v-icon left title="Registration End Date Time"
                        class="black--text text--lighten-1">mdi-timer</v-icon>
                    </v-col>
                    <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                      <span v-if="item.regEnddate">
                        <strong class="black--text text--lighten-1">{{ item.regEnddate }} {{ item.regEndtime }}</strong>
                      </span>
                      <span v-else>
                        <strong class="black--text text--lighten-1"> - </strong>
                      </span>
                    </v-col>
                  </v-row>
                  <!--
                        <span v-if="item.applying == 'Criteria MisMatch' && !item.selected">
                          <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                              <strong class="red--text text--lighten-1"><v-icon left style="color: red">mdi mdi-thumb-down-outline</v-icon></strong>
                            </v-col>
                            <v-col cols="6" sm="6" style="margin: 0px !important; padding: 0px !important">
                              <strong class="red--text text--lighten-1">{{ item.criteriamsg }}</strong>
                            </v-col>
                          </v-row>
                        </span>-->
                  <span v-if="item.applying == 'Applied' && !item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">
                          <v-icon left style="color: green">mdi mdi-thumb-up-outline</v-icon>
                        </strong>
                      </v-col>
                      <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">{{ item.applying }}</strong>
                      </v-col>
                    </v-row>
                  </span>
                  <span v-else-if="item.applying == 'Applied' && item.selected || item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">
                          <v-icon left style="color: green">mdi mdi-thumb-up-outline</v-icon>
                        </strong>
                      </v-col>
                      <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">Selected, Check Application Tracking Tab</strong>
                      </v-col>
                    </v-row>
                  </span>
                  <v-row>
                    <v-col cols="12" md="6">
                      <span v-if="item.registration == 'to_start'">
                        <span style="color:black;font-weight: bold;">
                          <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                          Registration Yet to Open
                        </span>
                      </span>
                      <span v-else-if="item.registration == 'Completed'">
                        <span style="color:blue;font-weight: bold;">
                          <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                          TPO Process Is Completed
                        </span>
                      </span>
                      <span v-else-if="item.registration == 'ended' && item.applying != 'Applied'">
                        <span style="color:black;font-weight: bold;">
                          <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                          Registration Closed
                        </span>
                      </span>
                      <span v-else-if="item.applying == 'true' && !item.selected">
                        <span>
                          <center><v-btn dense color="primary" @click="applyWithCV(item)"
                              v-model="details_id">Apply</v-btn>
                          </center>
                        </span>
                      </span>
                      <span
                        v-else-if="item.applying == 'Applied' || item.criteriamsg == 'You are added by Tpo in company' || item.applying == 'Selected'">
                        <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                        <span class="button" v-if="item.isallanswred == false">
                          <button type="submit" @click="newQuestionDialog(item)" class="button">Questions</button>
                        </span>
                      </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn color="primary" @click="finddetails(item)" v-model="details_id" dense>
                        More
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.selected && item.learneraccepted">
                    <v-img class="mx-auto" max-height="100" max-width="100" src="./tp.jpg" alt="Offer Accepted"></v-img>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <br>
      <br>
      <v-card v-if="companyselected_list.length > 0">
        <v-toolbar dense class="tc4-title">
          <v-toolbar-title>Companies : Applications Process Completed</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3" v-for="item in companyselected_list" :key="item">
              <v-card style="margin: 5px; height: 100%">
                <v-card-title class="c4-title">
                  {{ item.name }} :
                  <span>: {{ item.companytype }}</span>
                </v-card-title>
                <v-card-text>
                  <div style="padding: 4px"></div>
                  <v-row style="margin: 0px !important; padding: 0px !important">
                    <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                      <v-icon left class="black--text text--lighten-1">mdi-rename-box</v-icon>
                    </v-col>
                    <v-col cols="6" md="6" style="margin: 0px !important;padding: 0px !important;align: right;">
                      <strong class="black--text text--lighten-1">{{ item.placementtype }}</strong>
                      <span v-if="item.placementtype == 'Internship'">
                        <strong class="black--text text--lighten-1">- {{ item.internshiptype }}</strong>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row style="margin: 0px !important; padding: 0px !important">
                    <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                      <v-icon left title="Registration End Date Time"
                        class="black--text text--lighten-1">mdi-timer</v-icon>
                    </v-col>
                    <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                      <span v-if="item.regEnddate">
                        <strong class="black--text text--lighten-1">{{ item.regEnddate }} {{ item.regEndtime }}</strong>
                      </span>
                      <span v-else>
                        <strong class="black--text text--lighten-1"> - </strong>
                      </span>
                    </v-col>
                  </v-row>
                  <!--
                        <span v-if="item.applying == 'Criteria MisMatch' && !item.selected">
                          <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                              <strong class="red--text text--lighten-1"><v-icon left style="color: red">mdi mdi-thumb-down-outline</v-icon></strong>
                            </v-col>
                            <v-col cols="6" sm="6" style="margin: 0px !important; padding: 0px !important">
                              <strong class="red--text text--lighten-1">{{ item.criteriamsg }}</strong>
                            </v-col>
                          </v-row>
                        </span>-->
                  <span v-if="item.applying == 'Applied' && !item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">
                          <v-icon left style="color: green">mdi mdi-thumb-up-outline</v-icon>
                        </strong>
                      </v-col>
                      <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">{{ item.applying }}</strong>
                      </v-col>
                    </v-row>
                  </span>
                  <span v-else-if="item.applying == 'Applied' && item.selected || item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">
                          <v-icon left style="color: green">mdi mdi-thumb-up-outline</v-icon>
                        </strong>
                      </v-col>
                      <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">Selected, Check Application Tracking Tab</strong>
                      </v-col>
                    </v-row>
                  </span>
                  <v-row>
                    <v-col cols="12" md="6">
                      <span v-if="item.registration == 'to_start'">
                        <span style="color:black;font-weight: bold;">Registration Yet to Open</span>
                      </span>
                      <span v-else-if="item.registration == 'Completed'">
                        <span style="color:blue;font-weight: bold;">
                          <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                          TPO Process Is Completed
                        </span>
                      </span>
                      <span v-else-if="item.registration == 'ended' && item.applying != 'Applied'">
                        <span style="color:black;font-weight: bold;">
                          <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                          Registration Closed
                        </span>
                      </span>
                      <span v-else-if="item.applying == 'true' && !item.selected">
                        <span>
                          <center><v-btn dense color="primary" @click="applyWithCV(item)"
                              v-model="details_id">Apply</v-btn>
                          </center>
                        </span>
                      </span>
                      <span
                        v-else-if="item.applying == 'Applied' || item.criteriamsg == 'You are added by Tpo in company' || item.applying == 'Selected'">
                        <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                        <span class="button" v-if="item.isallanswred == false">
                          <button type="submit" @click="newQuestionDialog(item)" class="button">Questions</button>
                        </span>
                      </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn color="primary" @click="finddetails(item)" v-model="details_id" dense>
                        More
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.selected && item.learneraccepted">
                    <v-img class="mx-auto" max-height="100" max-width="100" src="./tp.jpg" alt="Offer Accepted"></v-img>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <br>
      <v-card v-if="companyprocesscompleted_list.length > 0">
        <v-toolbar dense class="tc2-title">
          <v-toolbar-title>Companies : Process Completed</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3" v-for="item in companyprocesscompleted_list" :key="item">
              <v-card style="margin: 5px; height: 100%">
                <v-card-title class="c2-title">
                  {{ item.name }} :
                  <span>: {{ item.companytype }}</span>
                </v-card-title>
                <v-card-text>
                  <div style="padding: 4px"></div>
                  <v-row style="margin: 0px !important; padding: 0px !important">
                    <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                      <v-icon left class="black--text text--lighten-1">mdi-rename-box</v-icon>
                    </v-col>
                    <v-col cols="6" md="6" style="margin: 0px !important;padding: 0px !important;align: right;">
                      <strong class="black--text text--lighten-1">{{ item.placementtype }}</strong>
                      <span v-if="item.placementtype == 'Internship'">
                        <strong class="black--text text--lighten-1">- {{ item.internshiptype }}</strong>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row style="margin: 0px !important; padding: 0px !important">
                    <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                      <v-icon left title="Registration End Date Time"
                        class="black--text text--lighten-1">mdi-timer</v-icon>
                    </v-col>
                    <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                      <span v-if="item.regEnddate">
                        <strong class="black--text text--lighten-1">{{ item.regEnddate }} {{ item.regEndtime }}</strong>
                      </span>
                      <span v-else>
                        <strong class="black--text text--lighten-1"> - </strong>
                      </span>
                    </v-col>
                  </v-row>
                  <!--
                        <span v-if="item.applying == 'Criteria MisMatch' && !item.selected">
                          <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                              <strong class="red--text text--lighten-1"><v-icon left style="color: red">mdi mdi-thumb-down-outline</v-icon></strong>
                            </v-col>
                            <v-col cols="6" sm="6" style="margin: 0px !important; padding: 0px !important">
                              <strong class="red--text text--lighten-1">{{ item.criteriamsg }}</strong>
                            </v-col>
                          </v-row>
                        </span>
                        -->
                  <span v-if="item.applying == 'Applied' && !item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">
                          <v-icon left style="color: green">mdi mdi-thumb-up-outline</v-icon>
                        </strong>
                      </v-col>
                      <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">{{ item.applying }}</strong>
                      </v-col>
                    </v-row>
                  </span>
                  <span v-else-if="item.applying == 'Applied' && item.selected || item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">
                          <v-icon left style="color: green">mdi mdi-thumb-up-outline</v-icon>
                        </strong>
                      </v-col>
                      <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">Selected, Check Application Tracking Tab</strong>
                      </v-col>
                    </v-row>
                  </span>
                  <v-row>
                    <v-col cols="12" md="6">
                      <span v-if="item.registration == 'to_start'">
                        <span style="color:black;font-weight: bold;">Registration Yet to Open</span>
                      </span>
                      <span v-else-if="item.registration == 'Completed'">
                        <span style="color:blue;font-weight: bold;">
                          <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                          TPO Process Is Completed
                        </span>
                      </span>
                      <span v-else-if="item.registration == 'ended' && item.applying != 'Applied'">
                        <span style="color:black;font-weight: bold;">Registration Closed</span>
                      </span>
                      <span v-else-if="item.applying == 'true' && !item.selected">
                        <span>
                          <center><v-btn dense color="primary" @click="applyWithCV(item)"
                              v-model="details_id">Apply</v-btn>
                          </center>
                        </span>
                      </span>
                      <span
                        v-else-if="item.applying == 'Applied' || item.criteriamsg == 'You are added by Tpo in company' || item.applying == 'Selected'">
                        <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                        <span class="button" v-if="item.isallanswred == false">
                          <button type="submit" @click="newQuestionDialog(item)" class="button">Questions</button>
                        </span>
                      </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn color="primary" @click="finddetails(item)" v-model="details_id" dense>
                        More
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.selected && item.learneraccepted">
                    <v-img class="mx-auto" max-height="100" max-width="100" src="./tp.jpg" alt="Offer Accepted"></v-img>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <br>
      <v-card v-if="companyregistrationclosed_list.length > 0">
        <v-toolbar dense class="tc1-title">
          <v-toolbar-title>Companies : Registration Closed</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3" v-for="item in companyregistrationclosed_list" :key="item">
              <v-card style="margin: 5px; height: 100%">
                <v-card-title class="c1-title">
                  {{ item.name }} :
                  <span>: {{ item.companytype }}</span>
                </v-card-title>
                <v-card-text>
                  <div style="padding: 4px"></div>
                  <v-row style="margin: 0px !important; padding: 0px !important">
                    <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                      <v-icon left class="black--text text--lighten-1">mdi-rename-box</v-icon>
                    </v-col>
                    <v-col cols="6" md="6" style="margin: 0px !important;padding: 0px !important;align: right;">
                      <strong class="black--text text--lighten-1">{{ item.placementtype }}</strong>
                      <span v-if="item.placementtype == 'Internship'">
                        <strong class="black--text text--lighten-1">- {{ item.internshiptype }}</strong>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row style="margin: 0px !important; padding: 0px !important">
                    <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                      <v-icon left title="Registration End Date Time"
                        class="black--text text--lighten-1">mdi-timer</v-icon>
                    </v-col>
                    <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                      <span v-if="item.regEnddate">
                        <strong class="black--text text--lighten-1">{{ item.regEnddate }} {{ item.regEndtime }}</strong>
                      </span>
                      <span v-else>
                        <strong class="black--text text--lighten-1"> - </strong>
                      </span>
                    </v-col>
                  </v-row>
                  <!--
                        <span v-if="item.applying == 'Criteria MisMatch' && !item.selected">
                          <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                              <strong class="red--text text--lighten-1"><v-icon left style="color: red">mdi mdi-thumb-down-outline</v-icon></strong>
                            </v-col>
                            <v-col cols="6" sm="6" style="margin: 0px !important; padding: 0px !important">
                              <strong class="red--text text--lighten-1">{{ item.criteriamsg }}</strong>
                            </v-col>
                          </v-row>
                        </span>-->

                  <span v-if="item.applying == 'Applied' && !item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">
                          <v-icon left style="color: green">mdi mdi-thumb-up-outline</v-icon>
                        </strong>
                      </v-col>
                      <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">{{ item.applying }}</strong>
                      </v-col>
                    </v-row>
                  </span>
                  <span v-else-if="item.applying == 'Applied' && item.selected || item.selected">
                    <v-row style="margin: 0px !important; padding: 0px !important">
                      <v-col cols="6" sm="2" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">
                          <v-icon left style="color: green">mdi mdi-thumb-up-outline</v-icon>
                        </strong>
                      </v-col>
                      <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                        <strong class="green--text text--lighten-1">Selected, Check Application Tracking Tab</strong>
                      </v-col>
                    </v-row>
                  </span>
                  <v-row>
                    <v-col cols="12" md="6">
                      <span v-if="item.registration == 'to_start'">
                        <span style="color:black;font-weight: bold;">Registration Yet to Open</span>
                      </span>
                      <span v-else-if="item.registration == 'Completed'">
                        <span style="color:blue;font-weight: bold;">
                          <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                          TPO Process Is Completed
                        </span>
                      </span>
                      <span v-else-if="item.registration == 'ended' && item.applying != 'Applied'">
                        <span style="color:black;font-weight: bold;">Registration Closed</span>
                      </span>
                      <span v-else-if="item.applying == 'true' && !item.selected">
                        <span>
                          <center><v-btn dense color="primary" @click="applyWithCV(item)"
                              v-model="details_id">Apply</v-btn>
                          </center>
                        </span>
                      </span>
                      <span
                        v-else-if="item.applying == 'Applied' || item.criteriamsg == 'You are added by Tpo in company' || item.applying == 'Selected'">
                        <v-btn color="primary" @click="viewRoundinfo(item.compantOfferingLearner)">Track</v-btn><br>
                        <span class="button" v-if="item.isallanswred == false">
                          <button type="submit" @click="newQuestionDialog(item)" class="button">Questions</button>
                        </span>
                      </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn color="primary" @click="finddetails(item)" v-model="details_id" dense>
                        More
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.selected && item.learneraccepted">
                    <v-img class="mx-auto" max-height="100" max-width="100" src="./tp.jpg" alt="Offer Accepted"></v-img>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog_apply" persistent max-width="800">
            <v-card>
              <v-card-title class="card-title">Apply to company<v-spacer></v-spacer> <v-icon class="mr-2  x-large"
                  @click="close()" color="error">mdi-close-circle-outline</v-icon></v-card-title>
              <input type="hidden" v-model="dataItem" />
              <v-spacer></v-spacer>

              <span v-for="(i, index) in offering_questions_list" :key=index>
                <v-row max-width="555">
      <v-col cols="10" sm="10" class="mx-auto">
        <strong>Q{{ index + 1 }}:</strong> 
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ i.question }}</span>
          </template>
          <span>{{ i.remark }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
                <v-row
                  v-if="i.question_multichoice != true && i.question_choice != true && i.is_attachmentrequired != true && i.is_attachment_with_description != true"
                  max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto">
                    <v-text-field v-model="i.descrAnswer" placeholder="Enter Your Answer" :hint="i.remark" outlined clearable required>
                    </v-text-field>
                    
                  </v-col>

                </v-row>
                <v-row v-else-if="i.is_attachmentrequired == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px"><br />
                    <v-row>
                      <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file"
                        show-size required="true" clearable v-model="i.answer_file" :hint="i.remark"></v-file-input>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.is_attachment_with_description == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto">
                    <v-text-field v-model="i.descrAnswer" style="margin-top:-5px" placeholder="Enter Your Answer"
                      clearable outlined required :hint="i.remark">
                    </v-text-field>
                  </v-col>
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-50px">
                    <v-file-input type="file" style="margin-top:30px;" placeholder="Select Attachment"
                      prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                      v-model="i.answer_file" :hint="i.remark"></v-file-input>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.question_multichoice == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row>
                      <v-col cols="3" sm="3" v-for="(role, index1) in i.optionlist" :key="index1">
                        <!-- <v-checkbox :label="role.answer" color="success" v-model="role.isSelectedOP" :hint="i.remark"></v-checkbox> -->
                        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on"><v-checkbox :label="role.answer" color="success" v-model="role.isSelectedOP" :hint="i.remark"></v-checkbox></span>
          </template>
          <span>{{ role.option_remark }}</span>
        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-else-if="i.question_choice == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row>
                      <v-col cols="10" sm="9">
                        <span  v-for="n in i.optionlist" :key="n">
                        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on"><v-radio-group row v-model="i.singleanswer">
                          <v-radio :label="`${n.answer}`" :value="n" :hint="i.remark"></v-radio>
                        </v-radio-group></span>
          </template>
          <span>{{ n.option_remark }}</span>
        </v-tooltip>
        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

              </span>
              <v-row max-width="555">
                <v-col cols="10" sm="10" class="mx-auto">
                  <label class="lbl">Your CVs<span class="required">*</span> :</label>
                  <v-select :items="cvList" v-model="cvFile" item-text="filename" item-value="id"
                    :rules="[v => !!v || 'required']" ></v-select>
                </v-col>
              </v-row>

              <v-card-actions class="d-flex-center ma-auto">
                <v-spacer></v-spacer>
                <v-row>
                  <!-- <v-btn elevation="2" raised class="logi" text @click="close()">Cancel</v-btn> -->
                  <v-btn elevation="2" raised class="logi" text
                    @click="applyCompany(offering_questions_list)">Submit</v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog v-model="question_dialog" persistent max-width="800">
            <v-card>
              <v-card-title class="card-title">Apply to company<v-spacer></v-spacer> <v-icon class="mr-2  x-large"
                  @click="close()" color="error">mdi-close-circle-outline</v-icon> </v-card-title>
              <input type="hidden" v-model="dataItem" />
              <v-spacer></v-spacer>


              <span v-for="(i, index) in offering_questions_list" :key=index>
                <v-row max-width="555">
      <v-col cols="10" sm="10" class="mx-auto">
        <strong>Q{{ index + 1 }}:</strong> 
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ i.question }}</span>
          </template>
          <span>{{ i.remark }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
                <v-row
                  v-if="i.question_multichoice != true && i.question_choice != true && i.is_attachmentrequired != true && i.is_attachment_with_description != true"
                  max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto">
                    <v-text-field v-model="i.descrAnswer" placeholder="Enter Your Answer" clearable outlined :hint="i.remark" required>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.is_attachment_with_description == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto">
                    <v-text-field v-model="i.descrAnswer" style="margin-top:-5px" placeholder="Enter Your Answer"
                      clearable outlined :hint="i.remark" required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-50px">
                    <v-file-input type="file" style="margin-top:30px;" placeholder="Select Attachment"
                      prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                      v-model="i.answer_file" :hint="i.remark"></v-file-input>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.is_attachmentrequired == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row><br />
                      <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file"
                        show-size required="true" clearable v-model="i.answer_file" :hint="i.remark" ></v-file-input>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.question_multichoice == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row>
                      <v-col cols="3" sm="3" v-for="(role, index) in i.optionlist" :key="index">
                        <!-- <v-checkbox :label="role.answer" color="success" v-model="role.isSelectedOP" :hint="i.remark"></v-checkbox> -->
                        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on"><v-checkbox :label="role.answer" color="success" v-model="role.isSelectedOP" :hint="i.remark"></v-checkbox></span>
          </template>
          <span>{{ role.option_remark }}</span>
        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.question_choice == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row>
                      <v-col cols="10" sm="9">
                        <!-- <v-radio-group row v-model="i.singleanswer">
                          <v-radio v-for="n in i.optionlist" :key="n" :label="` ${n.answer}`" :value="n" :hint="i.remark"></v-radio>
                        </v-radio-group> -->
                        <span  v-for="n in i.optionlist" :key="n">
                        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on"><v-radio-group row v-model="i.singleanswer">
                          <v-radio :label="`${n.answer}`" :value="n" :hint="i.remark"></v-radio>
                        </v-radio-group></span>
          </template>
          <span>{{ n.option_remark }}</span>
        </v-tooltip>
        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>


              </span>
              <v-card-actions class="d-flex-center ma-auto">
                <v-spacer></v-spacer>
                <v-row>
                  <!-- <v-btn elevation="2" raised class="logi" text @click="closequestion()">cancel</v-btn> -->
                  <v-btn elevation="2" raised class="logi" text
                    @click="applyWOcvWques(offering_questions_list)">Submit</v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>



      <template>
        <v-row justify="center">
          <v-dialog v-model="newquestion_dialog" persistent max-width="600">
            <v-card>
              <v-card-title class="card-title">Answer Questions<v-spacer></v-spacer> <v-icon class="mr-2  x-large"
                  @click="newquestion_dialog = false" color="error">mdi-close-circle-outline</v-icon> </v-card-title>
              <input type="hidden" v-model="dataItem" />
              <v-spacer></v-spacer>


              <span v-for="(i, index) in offeringnew_questions_list" :key=index>
                <v-row max-width="555">
      <v-col cols="10" sm="10" class="mx-auto">
        <strong>Q{{ index + 1 }}:</strong> 
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ i.question }}</span>
          </template>
          <span>{{ i.remark }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
                
                <v-row
                  v-if="i.question_multichoice != true && i.question_choice != true && i.is_attachmentrequired != true && i.is_attachment_with_description != true"
                  max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto">
                    <v-text-field v-model="i.descrAnswer" placeholder="Enter Your Answer" outlined :hint="i.remark" clearable required>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.is_attachment_with_description == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto">
                    <v-text-field v-model="i.descrAnswer" style="margin-top:-5px" placeholder="Enter Your Answer"
                      clearable outlined :hint="i.remark" required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-50px">
                    <v-file-input type="file" style="margin-top:30px;" placeholder="Select Attachment"
                      prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                      v-model="i.answer_file" :hint="i.remark" ></v-file-input>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.is_attachmentrequired == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row><br />
                      <v-file-input type="file" style="margin-top:30px;" placeholder="Select Attachment"
                        prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                        v-model="i.answer_file" :hint="i.remark" ></v-file-input>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.question_multichoice == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row>
                      <v-col cols="3" sm="3" v-for="(role, index) in i.optionlist" :key="index">
                        <!-- <v-checkbox :label="role.answer" color="success" v-model="role.isSelectedOP" :hint="i.remark" ></v-checkbox> -->
                        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on"><v-checkbox :label="role.answer" color="success" v-model="role.isSelectedOP" :hint="i.remark"></v-checkbox></span>
          </template>
          <span>{{ role.option_remark }}</span>
        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.question_choice == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row>
                      <v-col cols="10" sm="9">
                        <!-- <v-radio-group row v-model="i.singleanswer">
                          <v-radio v-for="n in i.optionlist" :key="n" :label="` ${n.answer}`" :value="n" :hint="i.remark" ></v-radio>
                        </v-radio-group> -->
                        <span  v-for="n in i.optionlist" :key="n">
                        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on"><v-radio-group row v-model="i.singleanswer">
                          <v-radio :label="`${n.answer}`" :value="n" :hint="i.remark"></v-radio>
                        </v-radio-group></span>
          </template>
          <span>{{ n.option_remark }}</span>
        </v-tooltip>
        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                  


              </span>
              <v-card-actions class="d-flex-center ma-auto">
                <v-spacer></v-spacer>
                <v-row>
                  <!-- <v-btn elevation="2" raised class="logi" text @click="closequestion()">cancel</v-btn> -->
                  <v-btn elevation="2" raised class="logi" text
                    @click="savenewQuestions(offeringnew_questions_list)">Submit</v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>










      <template>
        <div class="text-center">
          <v-dialog v-model="dialog_upload" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                Please wait....
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </div>

  </div>
</template>


<script>
import axios from "axios";
import acceptedimage from './tp.jpg'
export default {
  data: () => ({
    cvList: [],
    cvFile: null,

    loading: false,
    dataItem: null,
    question_dialog: false,
    newquestion_dialog: false,
    offering_questions: [],
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    load: false,
    radioGroup: "",
    dialog_apply: false,
    dialog_upload: false,
    dialog: false,
    dialog1: false,
    companyregistration_list: null,
    companyregistrationclosed_list: null,
    companyregistrationclosedwithapplied_list: null,
    companyselected_list: null,
    companyprocesscompleted_list: null,
    details_id: "",
    question: null,
    singleanswerquestioncount: 0,
    ispersonaldetailsfreezed: false,
    isexaminationdetailsfreezed: false,
    isacademicsdetailsfreezed: false,
    offeringnew_questions_list: [],
    offering_questions_list: [],
    ismarkszeromsg: "",
    ismarkszero: false,
    file: '',
    company: null,
    savedata: {
      file: null,
      company_name: '',
    },
    studentround_list: [],
    finalselected: "",
    headers: [
      {
        text: "Company",
        value: "name",
      },
      {
        text: "Company Type",
        value: "companytype",
      },
      {
        text: "Scheduled Date",
        value: "from_schedule_date",
      },
      {
        text: "Domain",
        value: "industry",
      },
      {
        text: "Offering",
        value: "placementtype",
      },
      {
        text: "Internship Type",
        value: "internshiptype",
      },
      {
        text: "Branches",
        value: "branches",
      },
      {
        text: "Institutes",
        value: "institutes",
      },
      {
        text: "Know More",
        value: "details",
      },
      {
        text: "Apply",
        value: "apply",
      },
    ],

    defaultItem: {
      id: "",
    },
  }),

  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    handleFileUpload() {
      // alert("in handle = "+file)
      this.file = this.$refs.file;
    },


    submitFile() {
      //  alert("in submit : "+this.file);
      let formData = new FormData();
      formData.append('file', this.file);
      axios
        .post("/TPOCompanyScheduling/saveOfferletter", formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.msg == "200") {

          } else {
            this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });

    },

    save(item) {

      if (this.savedata.file == null) {
        this.showSnackbar("#b71c1c", "Please Select File...");
      }
      else {
        let formData = new FormData();
        formData.append("file", this.savedata.file);
        formData.append("shortname", item.short_name);
        // formData.append("company_name", this.savedata.company_name);
        // window.alert(JSON.stringify(formData))
        axios
          .post("/TPOCompanyScheduling/saveOfferletter", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.msg == "200") {

              this.showSnackbar("#4caf50", "File Addded Successfully...");
              // document.getElementById("AddFile").style.display = "block";
              //  this.onLoad(); // show snackbar on success
            } else {
              //    this.dialog_upload=false;
              this.showSnackbar("#b71c1c", "File Already Present!!!");
              //      document.getElementById("AddFile").style.display = "block";
            }
          })
          .catch((error) => {
            window.console.log(error);
          });


      }
    },
    applyWithCV(item) {

      // alert(this.ispersonaldetailsfreezed);
      if (this.ismarkszero == true) {
        this.showSnackbar("#b71c1c", this.ismarkszeromsg);
        return;
      }
      if (this.ispersonaldetailsfreezed == false) {
        this.showSnackbar("#b71c1c", "Please Freez Personal Details first");
        return;
      }
      if (this.isexaminationdetailsfreezed == false) {
        this.showSnackbar("#b71c1c", "Please Freez Examination Details first");
        return;
      }
      if (this.isacademicsdetailsfreezed == false) {
        this.showSnackbar("#b71c1c", "Please Freez Academic Details first");
        return;
      }


      // alert("in applyWithCV"+item.questions_available);
      // alert("cv "+item.cvrequired)
      if (item.cvrequired == false && item.questions_available == true) {
        //   alert("question avaiable true cv false")
        this.dataItem = item;
        const formData = {
          companyOfferingid: this.dataItem.id,
        };
        axios
          .post("/StudentApplicationTrack/getOfferingQustion", formData)
          .then((res) => {
            console.log(res.data);
            if (res.data.msg == "success") {
              this.offering_questions_list = res.data.offering_questions_list
              console.log()

              this.question_dialog = true
            } else if (res.data.msg == "blocked_reason") {
              this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", error); // show snackbar
            // window.alert(error)
          })
      }
      else if (item.cvrequired == false && item.questions_available != true) {

        let formData = new FormData();
        formData.append("companyOfferingid", item.id)

        axios
          .post("/StudentApplicationTrack/applytocompany_mobile", formData)
          .then((res) => {
            console.log(res.data);
            if (res.data.msg == "success") {
              this.showSnackbar("#4CAF50", "You Have Successfully Applied For Company !");
              this.init();
            } else if (res.data.msg == "isblocked") {
              this.showSnackbar("#b71c1c", "Sorry, You Have Already Accepted Offer From One Company !");
              this.init();
            } else if (res.data.msg == "blocked_reason") {
              this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", error); // show snackbar
            // window.alert(error)
          })
      } else if (item.cvrequired == true && item.questions_available == true) {
        //  alert("in cv true questio true")
        this.dataItem = item;
        const formData = {
          companyOfferingid: this.dataItem.id,
        };
        axios
          .post("/StudentApplicationTrack/getOfferingQustion", formData)
          .then((res) => {
            console.log(res.data);
            if (res.data.msg == "success") {
              this.offering_questions_list = res.data.offering_questions_list

              this.dialog_apply = true;
            } else if (res.data.msg == "blocked_reason") {
              this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", error); // show snackbar
            // window.alert(error)
          })
      } else if (item.cvrequired == true && item.questions_available != true) {
        //  alert("in cv true questio false")
        this.dataItem = item;
        const formData = {
          companyOfferingid: this.dataItem.id,
        };
        axios
          .post("/StudentApplicationTrack/getOfferingQustion", formData)
          .then((res) => {
            console.log(res.data);
            if (res.data.msg == "success") {
              this.offering_questions_list = res.data.offering_questions_list
              console.log("size ");
              this.dialog_apply = true;
            } else if (res.data.msg == "blocked_reason") {
              this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", error); // show snackbar
            // window.alert(error)
          })
      }
    },
    applyWOcvWques(questionItem) {
      //alert(this.dataItem);
      // console.log("applyWOcvWques")
      var count = 0;
      for (var k in this.offering_questions_list) {
        if (this.offering_questions_list[k].question_choice == true) {
          if (this.offering_questions_list[k].singleanswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }
        if (this.offering_questions_list[k].is_attachmentrequired == true) {
          console.log("file");
          console.log(this.offering_questions_list[k].answer_file);
          if (this.offering_questions_list[k].answer_file == null) {
            this.showSnackbar("#b71c1c", "Attach file as an answer..");
            return;
          }
        }
        if (this.offering_questions_list[k].is_attachment_with_description == true) {
          console.log("file");
          console.log(this.offering_questions_list[k].answer_file);
          if (this.offering_questions_list[k].answer_file == null) {
            this.showSnackbar("#b71c1c", "Attach file as an answer...");
            return;
          } if (this.offering_questions_list[k].descrAnswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions..");
            return;
          }
        }
        if (this.offering_questions_list[k].question_choice != true && this.offering_questions_list[k].question_multichoice != true && this.offering_questions_list[k].is_attachmentrequired != true) {
          console.log("hiii");
          console.log(this.offering_questions_list[k].descrAnswer);
          if (this.offering_questions_list[k].descrAnswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions..");
            return;
          }
        }
        if (this.offering_questions_list[k].question_multichoice == true) {
          console.log("in multi")
          console.log(count)
          for (var a in this.offering_questions_list[k].optionlist) {
            console.log(this.offering_questions_list[k].optionlist[a].isSelectedOP)
            if (this.offering_questions_list[k].optionlist[a].isSelectedOP == true) {
              count++;
            }
          }
          console.log(count);
          if (count == 0) {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }

      }
      let optionlistnew = []
      let formData = new FormData();
      formData.append("size", this.offering_questions_list.length)
      formData.append("companyOfferingid", this.dataItem.id)
      for (var k in this.offering_questions_list) {
        formData.append("id" + k, this.offering_questions_list[k].id);
        if (this.offering_questions_list[k].question_choice == true) {
          formData.append("singleanswer" + k, JSON.stringify(this.offering_questions_list[k].singleanswer));
        }
        else if (this.offering_questions_list[k].is_attachmentrequired == true) {
          formData.append("answer_file" + k, this.offering_questions_list[k].answer_file);
        }
        else if (this.offering_questions_list[k].is_attachment_with_description == true) {
          formData.append("descrAnswer" + k, this.offering_questions_list[k].descrAnswer);
          formData.append("answer_file" + k, this.offering_questions_list[k].answer_file);
        } else if (this.offering_questions_list[k].question_multichoice == true) {
          for (var a in this.offering_questions_list[k].optionlist) {
            console.log(this.offering_questions_list[k].optionlist[a].isSelectedOP)
            if (this.offering_questions_list[k].optionlist[a].isSelectedOP == true) {
              optionlistnew.push(this.offering_questions_list[k].optionlist[a])
            }
          }
          formData.append("optionlist" + k, JSON.stringify(optionlistnew));
        } else if (this.offering_questions_list[k].question_choice != true && this.offering_questions_list[k].question_multichoice != true && this.offering_questions_list[k].is_attachmentrequired != true && this.offering_questions_list[k].is_attachment_with_description != true) {
          formData.append("descrAnswer" + k, this.offering_questions_list[k].descrAnswer);
        }

      }
      // const data={
      //   questionItem: questionItemarray,
      //   companyOfferingid:this.dataItem.id,
      // };
      axios
        .post("/StudentApplicationTrack/applytocompany_mobile", formData)
        .then((res) => {
          console.log("res.data");
          console.log(res.data);
          if (res.data.msg == "success") {
            this.question_dialog = false;
            this.init();
            this.showSnackbar("#4caf50", "You Have Applied Successfully...");

          } else if (res.data.msg == "blocked_reason") {
            this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
            this.question_dialog = false;
          } else {
            this.showSnackbar("#b71c1c", res.data.errormsg);
            this.question_dialog = false;
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    savenewQuestions(questionItem) {
      console.log(" save new");
      console.log(this.dataItem1.id);
      var count = 0;
      for (var k in questionItem) {
        console.log("in offering ")
        if (questionItem[k].question_choice == true) {
          if (questionItem[k].singleanswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }
        if (questionItem[k].is_attachmentrequired == true) {
          if (questionItem[k].answer_file == null) {
            this.showSnackbar("#b71c1c", "Attach file as an answer...");
            return;
          }
        }
        if (questionItem[k].is_attachment_with_description == true) {
          console.log("file");
          console.log(questionItem[k].answer_file);
          if (questionItem[k].answer_file == null) {
            this.showSnackbar("#b71c1c", "Attach file as an answer ...");
            return;
          } if (questionItem[k].descrAnswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions..");
            return;
          }
        }
        if (questionItem[k].question_choice != true && questionItem[k].question_multichoice != true && questionItem[k].is_attachmentrequired != true) {
          // console.log("hiii")
          if (questionItem[k].descrAnswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }
        if (questionItem[k].question_multichoice == true) {
          console.log("in multi")
          console.log(count)
          for (var a in questionItem[k].optionlist) {
            console.log(questionItem[k].optionlist[a].isSelectedOP)
            if (questionItem[k].optionlist[a].isSelectedOP == true) {
              count++;
            }
          }
          console.log(count);
          if (count == 0) {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }
      }
      // const data={
      //   questionItem: questionItem,
      // };
      let optionlistnew = []
      let formData = new FormData();
      formData.append("size", questionItem.length)
      formData.append("companyOfferingid", this.dataItem1.id)
      for (var k in questionItem) {
        formData.append("id" + k, questionItem[k].id);
        if (questionItem[k].question_choice == true) {
          formData.append("singleanswer" + k, JSON.stringify(questionItem[k].singleanswer));
        }
        else if (questionItem[k].is_attachmentrequired == true) {
          formData.append("answer_file" + k, questionItem[k].answer_file);
        }
        else if (questionItem[k].is_attachment_with_description == true) {
          formData.append("descrAnswer" + k, questionItem[k].descrAnswer);
          formData.append("answer_file" + k, questionItem[k].answer_file);
        } else if (questionItem[k].question_multichoice == true) {

          for (var a in questionItem[k].optionlist) {
            console.log(questionItem[k].optionlist[a].isSelectedOP)
            if (questionItem[k].optionlist[a].isSelectedOP == true) {
              optionlistnew.push(questionItem[k].optionlist[a])
            }
          }
          formData.append("optionlist" + k, JSON.stringify(optionlistnew));
        } else if (questionItem[k].question_choice != true && questionItem[k].question_multichoice != true && questionItem[k].is_attachmentrequired != true && questionItem[k].is_attachment_with_description != true) {
          formData.append("descrAnswer" + k, questionItem[k].descrAnswer);
        }
        // formData.append("question"+k, this.offering_questions_list[k].question);
      }
      axios
        .post("/StudentApplicationTrack/savenewQuestions", formData)
        .then((res) => {
          console.log("res.data");
          console.log(res.data);
          if (res.data.msg == "success") {
            this.newquestion_dialog = false;
            this.init();
            this.showSnackbar("#4caf50", "Answers Saved Successfully...");
          } else {
            this.showSnackbar("#b71c1c", res.data.errormsg);
            this.question_dialog = false;
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    init() {
      this.loading = true;
      axios
        .post("/TPOCompanyScheduling/schedulesdcopaniesone")
        .then((res) => {
          if (res.data.msg == "200") {
            this.companyregistration_list = res.data.companyregistration_list;
            this.companyregistrationclosed_list = res.data.companyregistrationclosed_list;
            this.companyregistrationclosedwithapplied_list = res.data.companyregistrationclosedwithapplied_list;
            this.companyselected_list = res.data.companyselected_list;
            this.companyprocesscompleted_list = res.data.companyprocesscompleted_list;
            this.cvList = res.data.cvList;
            this.load = true;
            this.loading = false;
            this.isacademicsdetailsfreezed = res.data.isacademicsdetailsfreezed;
            this.isexaminationdetailsfreezed = res.data.isexaminationdetailsfreezed;
            this.ispersonaldetailsfreezed = res.data.ispersonaldetailsfreezed;
            this.ismarkszeromsg = res.data.ismarkszeromsg;
            this.ismarkszero = res.data.ismarkszero;
          } else if (res.data.msg == "201") {
            this.showSnackbar("#b71c1c", res.data.error);
          } else {
            this.showSnackbar("#b71c1c", "Something went wrong...");
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    viewRoundinfo(item) {
      const formData = {
        companyOfferingLearnerid: item,
      };
      axios
        .post("/StudentApplicationTrack/viewRoundInfo", formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.msg == "200") {
            this.company = res.data.company_list;
            this.studentround_list = res.data.studentround_list;
            this.finalselected = res.data.finalselected
            this.dialog1 = true;
          } else {
            this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
    },

    close() {
      this.dialog_apply = false;
      setTimeout(() => {
        this.dataItem = null;
        this.cvFile = null;
        // window.alert(`DataItemNow=${ this.dataItem } && Cv=${this.cvFile}`)
      }, 300);
    },
    closequestion() {
      this.question_dialog = false;
      setTimeout(() => {
        this.dataItem = null;
        // window.alert(`DataItemNow=${ this.dataItem } && Cv=${this.cvFile}`)
      }, 300);
    },

    finddetails(item) {
      // alert(item.id)
      this.$store.commit("setcompany_id", item.id);
      //  this.$router.push("/company-info");
      this.$router.push({ name: "company-info" });
    },
    applyCompany(item) {
      console.log("show qestion applyCompany");
      console.log(this.offering_questions_list);
      var count = 0;
      for (var k in this.offering_questions_list) {
        console.log("in offering ")
        if (this.offering_questions_list[k].question_choice == true) {
          if (this.offering_questions_list[k].singleanswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }
        if (this.offering_questions_list[k].question_choice != true && this.offering_questions_list[k].question_multichoice != true && this.offering_questions_list[k].is_attachmentrequired != true) {
          console.log("hiii");
          console.log(this.offering_questions_list[k].descrAnswer);
          if (this.offering_questions_list[k].descrAnswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }
        if (this.offering_questions_list[k].is_attachmentrequired == true) {
          if (this.offering_questions_list[k].answer_file == null) {
            this.showSnackbar("#b71c1c", "Attach file as an answer...");
            return;
          }
        }
        if (this.offering_questions_list[k].is_attachment_with_description == true) {
          console.log("file");
          console.log(this.offering_questions_list[k].answer_file);
          if (this.offering_questions_list[k].answer_file == null) {
            this.showSnackbar("#b71c1c", "Attach file as an answer...");
            return;
          } if (this.offering_questions_list[k].descrAnswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions..");
            return;
          }
        }
        if (this.offering_questions_list[k].question_multichoice == true) {
          console.log("in multi")
          console.log(count)
          for (var a in this.offering_questions_list[k].optionlist) {
            console.log(this.offering_questions_list[k].optionlist[a].isSelectedOP)
            if (this.offering_questions_list[k].optionlist[a].isSelectedOP == true) {
              count++;
            }
          }
          console.log(count);
          if (count == 0) {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }

      }
      //conl.log("h")
      if (this.cvFile == null) {
        this.showSnackbar("#b71c1c", "Please Select A File...");
      }
      else {
        this.dialog_upload = true;
        // const formData = {
        //   companyOfferingid: this.dataItem.id,
        //   cvFile:this.cvFile,
        //   questionItem: this.offering_questions_list,

        // };

        let formData = new FormData();
        formData.append("size", this.offering_questions_list.length)
        formData.append("cvFile", this.cvFile)
        formData.append("companyOfferingid", this.dataItem.id)
        for (var k in this.offering_questions_list) {
          let optionlistnew = []
          formData.append("id" + k, this.offering_questions_list[k].id);
          if (this.offering_questions_list[k].question_choice == true) {
            formData.append("singleanswer" + k, JSON.stringify(this.offering_questions_list[k].singleanswer));
          }
          else if (this.offering_questions_list[k].is_attachmentrequired == true) {
            formData.append("answer_file" + k, this.offering_questions_list[k].answer_file);
          }
          else if (this.offering_questions_list[k].is_attachment_with_description == true) {
            formData.append("descrAnswer" + k, this.offering_questions_list[k].descrAnswer);
            formData.append("answer_file" + k, this.offering_questions_list[k].answer_file);
          } else if (this.offering_questions_list[k].question_multichoice == true) {
            for (var a in this.offering_questions_list[k].optionlist) {
              if (this.offering_questions_list[k].optionlist[a].isSelectedOP == true) {
                optionlistnew.push(this.offering_questions_list[k].optionlist[a])
              }
            }
            formData.append("optionlist" + k, JSON.stringify(optionlistnew));
          } else if (this.offering_questions_list[k].question_choice != true && this.offering_questions_list[k].question_multichoice != true && this.offering_questions_list[k].is_attachmentrequired != true && this.offering_questions_list[k].is_attachment_with_description != true) {
            formData.append("descrAnswer" + k, this.offering_questions_list[k].descrAnswer);
          }
          // formData.append("question"+k, this.offering_questions_list[k].question);

        }
        axios
          .post("/StudentApplicationTrack/applytocompany_mobile", formData)
          .then((res) => {
            console.log(res.data);
            if (res.data.msg == "success") {
              this.showSnackbar("#4CAF50", "You Have Successfully Applied For Company !");
              this.dialog_upload = false;
              this.dialog_apply = false;
              this.close();
              this.init();
            } else if (res.data.msg == "isblocked") {
              this.showSnackbar("#b71c1c", "You Have Already Accepted Offer From One Company !");
              this.dialog_upload = false;
              this.dialog_apply = false;
              this.close();
              this.init();
            } else if (res.data.msg == "blocked_reason") {
              this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
              this.dialog_upload = false;
              this.close();
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
              this.dialog_upload = false;
              this.close();
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", error); // show snackbar
            // window.alert(error)
            this.dialog_upload = false;
            this.close();
          })
      }
    },

    newQuestionDialog(item) {
      this.dataItem1 = item;
      const formData = {
        companyOfferingid: this.dataItem1.id,
      };
      axios
        .post("/StudentApplicationTrack/getNewOfferingQustion", formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.msg == "success") {
            this.offeringnew_questions_list = res.data.offeringnew_questions_list

            this.newquestion_dialog = true;
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", error); // show snackbar
          // window.alert(error)
        })
    },
    applyCompany1() {
      // alrt("in applyCompany1")
      const formData = {
        companyOfferingid: this.dataItem,
      };
      // window.alert(`FormData ${JSON.stringify(formData)}`)
      axios
        .post("/StudentApplicationTrack/applyForCompany", formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.msg == "200") {
            this.showSnackbar("#4CAF50", "You Have Successfully Applied For Company !");
            this.dialog_upload = false;
            this.dialog_apply = false;
            this.close();
            this.init();
          } else if (res.data.msg == "blocked_reason") {
            this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
            this.dialog_upload = false;
            this.close();
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
            this.dialog_upload = false;
            this.close();
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", error); // show snackbar
          // window.alert(error)
          this.dialog_upload = false;
          this.close();
        })
    },
  }
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}

.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}

.c1-title {
  background-image: linear-gradient(-90deg, #e251b2, #94038a);
  color: #fff;
}

.c2-title {
  background-image: linear-gradient(-90deg, #f97979, #c82950);
  color: #fff;
}

.c3-title {
  background-image: linear-gradient(-90deg, #a3a0d4, #6247dd);
  color: #fff;
}

.c4-title {
  background-image: linear-gradient(-90deg, #73a47c, #03942c);
  color: #fff;
}

.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}

.tc1-title {
  background-image: linear-gradient(-90deg, #fff, #960596);
  color: #fff;
  border-radius: 3px;
}

.tc2-title {
  background-image: linear-gradient(-90deg, #fff, #960505);
  color: #fff;
  border-radius: 3px;
}

.tc3-title {
  background-image: linear-gradient(-90deg, #fff, #032037);
  color: #fff;
  border-radius: 3px;
}

.tc4-title {
  background-image: linear-gradient(-90deg, #fff, #034824);
  color: #fff;
  border-radius: 3px;
}

.logi {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}

.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}

.lbl {
  color: #1705e6;
}

.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.downbtn {
  float: right;
}

.logi1 {
  background-image: linear-gradient(-100deg, #6cf389, #0b9761);
  color: #fff;
}

.button {
  background-color: #000080;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;

  animation: blinking 1s infinite;
}

@keyframes blinking {
  0% {
    background-color: #ff3d50;

  }

  /* ADD MORE KEYFRAMES IF YOU WANT
  50% {
    background-color: #55d66b;
    border: 5px solid #126620;
  } */
  100% {
    background-color: #222291;
  }
}
</style>